<template>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(13.72549%,18.823529%,23.921569%);fill-opacity:1;"
      d="M 17.132812 11.648438 C 17.140625 9.414062 16.238281 7.269531 14.625 5.691406 L 8.984375 0.175781 C 8.875 0.0625 8.722656 0 8.566406 0 C 8.410156 0 8.257812 0.0625 8.148438 0.175781 L 2.507812 5.722656 C 0.894531 7.296875 -0.0078125 9.441406 0 11.675781 C 0 16.105469 3.523438 19.773438 7.96875 20.066406 L 7.96875 22.414062 C 7.96875 22.738281 8.238281 23 8.566406 23 C 8.894531 23 9.160156 22.738281 9.164062 22.414062 L 9.164062 20.035156 C 13.652344 19.734375 17.136719 16.066406 17.132812 11.644531 Z M 9.164062 18.863281 L 9.164062 16.78125 L 12.953125 13.054688 C 13.066406 12.949219 13.132812 12.800781 13.132812 12.644531 C 13.132812 12.488281 13.066406 12.339844 12.953125 12.234375 C 12.84375 12.121094 12.695312 12.058594 12.535156 12.058594 C 12.378906 12.058594 12.226562 12.121094 12.117188 12.234375 L 9.164062 15.136719 L 9.164062 7.746094 C 9.160156 7.421875 8.894531 7.160156 8.566406 7.160156 C 8.238281 7.160156 7.96875 7.421875 7.96875 7.746094 L 7.96875 13.171875 L 4 9.300781 C 3.890625 9.1875 3.738281 9.125 3.582031 9.125 C 3.421875 9.125 3.273438 9.1875 3.164062 9.300781 C 3.050781 9.40625 2.984375 9.554688 2.984375 9.710938 C 2.984375 9.867188 3.050781 10.015625 3.164062 10.121094 L 7.96875 14.84375 L 7.96875 18.894531 C 4.179688 18.601562 1.195312 15.460938 1.195312 11.675781 C 1.195312 9.738281 1.96875 7.921875 3.34375 6.570312 L 8.566406 1.410156 L 13.757812 6.511719 C 15.140625 7.863281 15.914062 9.703125 15.910156 11.617188 C 15.949219 15.402344 13 18.566406 9.164062 18.863281 Z M 9.164062 18.863281 "
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
